var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div'),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.addIngredientModal",modifiers:{"addIngredientModal":true}}],staticClass:"mb-2 align-self-end",attrs:{"variant":"outline-primary"},on:{"click":_vm.getFormulaIngredients}},[_vm._v(" "+_vm._s(_vm.$t("ingredientAdd"))+" ")])],1),(_vm.multiFormulaLoading)?_c('div',{staticClass:"d-flex align-items-center"},[_c('strong',[_vm._v(_vm._s(_vm.$t("loading")))]),_c('b-spinner',{staticClass:"ml-auto"})],1):(_vm.selectedSingleFormula.id == 0)?_c('b-table',{staticClass:"smaller-text",attrs:{"striped":"","responsive":"","small":"","bordered":"","fields":_vm.tumFormullerFields,"items":_vm.multiFormula[0].tumFormuller,"empty-text":_vm.$t('noData'),"show-empty":""},scopedSlots:_vm._u([{key:"cell(karisim)",fn:function(data){return [_c('div',{staticClass:"text-right"},[_c('span',{class:data.item.itemtextcolor == 'Green'
              ? 'text-success'
              : 'text-danger',attrs:{"id":data.item.ikey + '-karisim'}},[_vm._v(" "+_vm._s(parseFloat(data.item.karisim) .toFixed(_vm.ingredientDecimal) .replace(".", ",")))])])]}},{key:"cell(stok)",fn:function(data){return [_c('div',{staticClass:"d-flex pointer-event"},[_c('b-form-checkbox',{attrs:{"checked":data.item.stok,"size":"md"},nativeOn:{"change":function($event){data.item.stok = !data.item.stok}}})],1)]}},{key:"cell(aktif)",fn:function(data){return [_c('div',{staticClass:"d-flex pointer-event"},[_c('b-form-checkbox',{attrs:{"checked":data.item.aktif,"size":"md"},nativeOn:{"change":function($event){data.item.aktif = !data.item.aktif}}})],1)]}},{key:"cell(min)",fn:function(data){return [_c('div',{staticClass:"editable text-right align-items-center position-relative"},[(data.item.min > data.item.max && data.item.max != 0)?_c('feather-icon',{staticClass:"position-absolute",class:data.item.min > data.item.max && data.item.max != 0
              ? 'text-danger'
              : 'text-success',staticStyle:{"right":"0","top":"1px"},attrs:{"icon":"ArrowDownIcon"}}):_vm._e(),_c('b-form-input',{staticClass:"inp p-relative",class:data.item.min > data.item.max && data.item.max != 0
              ? 'text-danger'
              : 'text-success',staticStyle:{"font-size":"1rem"},attrs:{"id":data.item.ingredientname + 'min',"size":"sm","data-id":'nutrient-' + data.field.key + data.index,"value":parseFloat(data.item.min)
              .toFixed(_vm.ingredientDecimal)
              .replace(',', '.'),"type":"number","options":{
            numeral: true,
          }},on:{"change":function (v) { return (data.item.min = parseFloat(v)); },"focus":function (v) { return v.target.select(); }}})],1)]}},{key:"cell(max)",fn:function(data){return [_c('div',{staticClass:"editable text-right"},[_c('b-form-input',{staticClass:"inp",staticStyle:{"font-size":"1rem"},attrs:{"id":data.item.ingredientname + 'max',"size":"sm","data-id":'ingredient-' + data.field.key + data.index,"value":parseFloat(data.item.max)
              .toFixed(_vm.ingredientDecimal)
              .replace(',', '.'),"type":"number","options":{
            numeral: true,
          }},on:{"change":function (v) { return (data.item.max = parseFloat(v)); },"focus":function (v) { return v.target.select(); }}})],1)]}},{key:"cell(kayitlidanfark)",fn:function(data){return [_c('div',{staticClass:"text-right"},[_c('span',{class:data.item.karisim - data.item.kayitlikarisim >= 0
              ? ''
              : 'text-danger'},[_vm._v(" "+_vm._s(( parseFloat(data.item.karisim) - parseFloat(data.item.kayitlikarisim) ).toFixed(_vm.ingredientDecimal))+" ")])])]}}])}):_c('b-table',{staticClass:"smaller-text",attrs:{"striped":"","responsive":"","small":"","bordered":"","fields":_vm.hammaddelerFields,"items":_vm.multiFormula[0].hammaddeler.filter(
        function (x) { return x.rasyonid == _vm.selectedSingleFormula.id; }
      ),"empty-text":_vm.$t('noData'),"show-empty":""},scopedSlots:_vm._u([{key:"cell(karisim)",fn:function(data){return [_c('div',{staticClass:"text-right"},[_c('span',{class:data.item.itemtextcolor == 'Green'
              ? 'text-success'
              : 'text-danger',attrs:{"id":data.item.ikey + '-karisim'}},[_vm._v(" "+_vm._s(parseFloat(data.item.karisim) .toFixed(_vm.ingredientDecimal) .replace(".", ",")))])])]}},{key:"cell(stok)",fn:function(data){return [_c('div',{staticClass:"d-flex pointer-event"},[_c('b-form-checkbox',{attrs:{"checked":data.item.stok,"size":"md"},nativeOn:{"change":function($event){data.item.stok = !data.item.stok}}})],1)]}},{key:"cell(aktif)",fn:function(data){return [_c('div',{staticClass:"d-flex pointer-event"},[_c('b-form-checkbox',{attrs:{"checked":data.item.aktif,"size":"md"},nativeOn:{"change":function($event){data.item.aktif = !data.item.aktif}}})],1)]}},{key:"cell(min)",fn:function(data){return [_c('div',{staticClass:"editable text-right align-items-center position-relative"},[(data.item.min > data.item.max && data.item.max != 0)?_c('feather-icon',{staticClass:"position-absolute",class:data.item.min > data.item.max && data.item.max != 0
              ? 'text-danger'
              : 'text-success',staticStyle:{"right":"0","top":"1px"},attrs:{"icon":"ArrowDownIcon"}}):_vm._e(),_c('b-form-input',{staticClass:"inp p-relative",class:data.item.min > data.item.max && data.item.max != 0
              ? 'text-danger'
              : 'text-success',staticStyle:{"font-size":"1rem"},attrs:{"id":data.item.ingredientname + 'min',"size":"sm","data-id":'nutrient-' + data.field.key + data.index,"value":parseFloat(data.item.min)
              .toFixed(_vm.ingredientDecimal)
              .replace(',', '.'),"type":"number","options":{
            numeral: true,
          }},on:{"change":function (v) { return (data.item.min = parseFloat(v)); },"focus":function (v) { return v.target.select(); }}})],1)]}},{key:"cell(max)",fn:function(data){return [_c('div',{staticClass:"editable text-right"},[_c('b-form-input',{staticClass:"inp",staticStyle:{"font-size":"1rem"},attrs:{"id":data.item.ingredientname + 'max',"size":"sm","data-id":'ingredient-' + data.field.key + data.index,"value":parseFloat(data.item.max)
              .toFixed(_vm.ingredientDecimal)
              .replace(',', '.'),"type":"number","options":{
            numeral: true,
          }},on:{"change":function (v) { return (data.item.max = parseFloat(v)); },"focus":function (v) { return v.target.select(); }}})],1)]}},{key:"cell(arrow)",fn:function(ref){
          var item = ref.item;
return [(
          item.onerilenkarisim != 0 && item.onerilenkarisim > item.karisim
        )?_c('feather-icon',{staticClass:"text-success",attrs:{"icon":"ArrowUpIcon"}}):_vm._e(),(
          item.onerilenkarisim != 0 && item.onerilenkarisim < item.karisim
        )?_c('feather-icon',{staticClass:"text-danger",attrs:{"icon":"ArrowDownIcon"}}):_vm._e()]}},{key:"cell(kayitlidanfark)",fn:function(data){return [_c('div',{staticClass:"text-right"},[_c('span',{class:data.item.karisim - data.item.kayitlikarisim >= 0
              ? ''
              : 'text-danger'},[_vm._v(" "+_vm._s(( parseFloat(data.item.karisim) - parseFloat(data.item.kayitlikarisim) ).toFixed(_vm.priceDecimal))+" ")])])]}}])}),_c('b-modal',{attrs:{"id":"addIngredientModal","scrollable":"","title":_vm.$t('ingredients'),"cancel-title":"Close","ok-title":_vm.$t('ok'),"ok-only":""},on:{"ok":_vm.submitIngredient}},[_c('b-form-group',{attrs:{"label":_vm.$t('search')}},[_c('b-form-input',{staticClass:"inp",on:{"keyup":function($event){return _vm.changeItem()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('b-table-lite',{attrs:{"items":_vm.allIngredientsSearch,"fields":[
        {
          key: 'selected',
          label: '',
        },
        {
          key: 'ingredientname',
          label: _vm.$t('ingredientName'),
        } ]},scopedSlots:_vm._u([{key:"cell(selected)",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{key:item.id + '-' + item.selected,attrs:{"checked":item.selected == 1},nativeOn:{"change":function($event){return _vm.changeSelected(item)}}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }