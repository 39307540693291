<template>
  <div>
    <!-- Action -->
    <b-row>
      <b-col cols="12" sm="6"> </b-col>
      <b-col cols="12" sm="6" class="d-flex justify-content-end ml-auto">
        <b-button
          class="d-flex align-items-center ml-1"
          variant="outline-primary"
          size="sm"
          @click="addFormula"
        >
          <feather-icon icon="SaveIcon" size="20" />
          <span class="m-small ml-1">{{ $t("save") }}</span>
        </b-button>
      </b-col>
    </b-row>

    <!-- Table -->
    <b-row class="mt-1">
      <b-col cols="12">
        <b-table
          striped
          class="smaller-text"
          :fields="formulaFields"
          responsive=""
          small
          :items="formulas"
          :empty-text="$t('noData')"
        >
          <template #cell(select)="{ item }">
            <b-form-checkbox v-model="item.isselected"></b-form-checkbox>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { BRow, BCol, BButton, BFormCheckbox, BTable } from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTable,
    BFormCheckbox,
  },
  data() {
    return {
      getUserData: getUserData,
      formulas: [],
      formulaFields: [
        {
          key: "select",
          label: "",
        },
        {
          key: "code",
          label: this.$t("code"),
        },
        {
          key: "rationname",
          label: this.$t("formulaName"),
        },
      ],
    };
  },
  computed: {
    multiFormula() {
      return this.$store.state.formulasModule.multiFormula;
    },
    plantid() {
      return this.$store.state.app.selectedPlantId;
    },
  },
  async mounted() {
    this.formulas = await this.getFormulasForMultiGroup({
      plantid: this.$route.params.id ?? plantid,
      multiid: this.$route.params.formulaid,
    });
  },
  methods: {
    ...mapActions({
      getFormulasForMultiGroup: "formulasModule/getFormulasForMultiGroup",
      updateFormulasforMultiGroup: "formulasModule/updateFormulasforMultiGroup",
    }),
    addFormula() {
      this.updateFormulasforMultiGroup({
        plantid: this.$route.params.id ?? plantid,
        ids: this.formulas
          .filter((f) => f.isselected === true || f.isselected === "true")
          .map((f) => f.id),
        multiid: this.$route.params.formulaid,
      });
      this.$emit("close");
    },
  },
};
</script>

<style>
.smaller-text {
  font-size: 0.9rem;
}
#type-group,
#ext-group,
.detail {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding-top: 10px;
}
#type-group,
#ext-group {
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
