<template>
  <div>
    <div>
      <b-card>
        <!-- Media -->
        <div class="d-flex justify-content-between flex-column flex-md-row">
          <div>
            <b-media class="mb-2">
              <label for="select"> {{ $t("formulaName") }} </label>
              <v-select
                :options="formulaOptions"
                class="w-100"
                :reduce="(val) => val.value"
                :value="selectedSingleFormula.id"
                @input="(val) => setSelectedSingleFormula(val)"
              >
              </v-select>
            </b-media>
            <div class="d-flex flex-wrap">
              <b-form-checkbox
                class="mr-2"
                :checked="multiFormulaOptions.useMinProd"
                @input="(val) => setOptions({ useMinProd: val })"
              >
                <span class="user-select-none">
                  {{ $t("useMinProd") }}
                </span>
              </b-form-checkbox>
              <b-form-checkbox
                :checked="multiFormulaOptions.showInventoryCost"
                @input="(val) => setOptions({ showInventoryCost: val })"
              >
                <span class="user-select-none">
                  {{ $t("showInventoryCost") }}</span
                >
              </b-form-checkbox>
            </div>
          </div>
          {{ cozdu }}sss
          <div class="mt-2 mt-md-0">
            <div class="ml-0 ml-md-2">
              <b-button
                size="sm"
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="solveFormula()"
              >
                <div
                  class="d-flex flex-row flex-sm-column justify-content-start align-items-center"
                >
                  <component
                    :is="require('@/assets/images/icons/optimizeW.svg')"
                    color="text-truncate"
                    height="25"
                  />
                  <span class="m-small">
                    {{ $t("solve") }}
                  </span>
                </div>
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="saveFormula()"
              >
                <div
                  class="d-flex flex-row flex-sm-column justify-content-start align-items-center"
                >
                  <feather-icon icon="SaveIcon" size="25" />
                  <span class="m-small">
                    {{ $t("saveFormula") }}
                  </span>
                </div>
              </b-button>

              <b-button
                size="sm"
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="saveFormulaForProduction()"
              >
                <div
                  class="d-flex flex-row flex-sm-column justify-content-start align-items-center"
                >
                  <feather-icon icon="SaveIcon" size="25" />
                  <span class="m-small">
                    {{ $t("saveFormulaForProd") }}
                  </span>
                </div>
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="openPrintModal = !openPrintModal"
              >
                <div
                  class="d-flex flex-row flex-sm-column justify-content-start align-items-center"
                >
                  <feather-icon icon="PrinterIcon" size="25" />
                  <span class="m-small">
                    {{ $t("print") }}
                  </span>
                </div>
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="openFormulasModal = !openFormulasModal"
              >
                <div
                  class="d-flex flex-row flex-sm-column justify-content-start align-items-center"
                >
                  <feather-icon icon="FilePlusIcon" size="25" />
                  <span class="m-small">
                    {{ $t("formul") }}
                  </span>
                </div>
              </b-button>
            </div>
            <div class="mt-1">
              <h3>
                <b-button
                  v-if="cozdu == null"
                  class="d-flex align-items-center justify-content-center border-0 w-100 bg-yellow"
                >
                  <div class="d-flex mr-1">
                    <feather-icon size="25" icon="MinusCircleIcon" />
                  </div>
                  {{ $t("lastSolution") }}
                </b-button>
                <b-button
                  v-if="cozdu != null && !cozdu"
                  variant="danger"
                  class="d-flex align-items-center justify-content-center w-100"
                >
                  <div class="d-flex mr-1">
                    <feather-icon size="25" icon="XCircleIcon" />
                  </div>
                  {{ $t("notSolved") }}
                </b-button>
                <b-button
                  v-if="cozdu"
                  variant="success"
                  class="d-flex align-items-center justify-content-center w-100"
                >
                  <div class="d-flex mr-1">
                    <feather-icon size="25" icon="CheckCircleIcon" />
                  </div>
                  {{ $t("solved") }}
                </b-button>
              </h3>
            </div>
          </div>
        </div>
        <!-- User Info: Input Fields -->

        <!-- Action Buttons -->
      </b-card>
      <formula-infos></formula-infos>

      <b-card v-if="!openPrintModal && !openFormulasModal" class="mt-1">
        <slot></slot>
      </b-card>
    </div>
    <b-modal
      id="modalPrint"
      v-model="openPrintModal"
      :title="$t('printOptions')"
      centered
      size="xl"
      hide-footer
      class="modal-lg"
    >
      printModal
    </b-modal>
    <b-modal
      id="modalFormulas"
      v-model="openFormulasModal"
      :title="$t('formulas')"
      centered
      size="lg"
      class="modal-lg"
      ok-only
      :ok-title="$t('ok')"
    >
      <formula-modal></formula-modal>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BCard,
  BBadge,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import PriceLabel from "./PriceLabel.vue";
import { getUserData } from "@/auth/utils";
import vSelect from "vue-select";
import FormulaModal from "./FormulaModal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormulaInfos from "./FormulaInfos.vue";
export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    BBadge,
    BSpinner,
    BFormCheckbox,
    vSelect,
    FormulaModal,
    BSpinner,
    PriceLabel,
    FormulaInfos,
  },
  props: {
    formulaData: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      getUserData: getUserData,
      openPrintModal: false,
      openFormulasModal: false,
    };
  },
  computed: {
    formulaLoading() {
      return this.$store.state.formulasModule.multiFormulaLoading;
    },
    cozdu() {
      return this.$store.state.formulasModule.multiCozdu;
    },
    selectedSingleFormula() {
      return this.$store.state.formulasModule.selectedSingleFormula;
    },
    multiFormulaOptions() {
      return this.$store.state.formulasModule.multiFormulaOptions;
    },
    multiFormula() {
      return this.$store.state.formulasModule.multiFormula[0];
    },
    formulaOptions() {
      let opt = [{ label: this.$t("allFormulas"), value: 0 }];
      if (this.formulaData.formuller)
        opt.push(
          ...[
            ...this.formulaData.formuller.map((x) => ({
              label: x.rationname,
              value: x.id,
            })),
          ]
        );
      return opt;
    },
  },

  mounted() {
    if (getUserData().autosolve) {
      this.solveFormula();
    }
  },
  methods: {
    async solveFormula() {
      await setTimeout(() => {
        this.$store.dispatch("formulasModule/solveMultiFormulaService", {
          formula: this.formulaData,
          plantid: this.$store.state.app.selectedPlantId,
        });
        this.showFeasibleToast();
      }, 0);
    },
    showFeasibleToast() {
      if (this.cozdu) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$t("solved"),
          },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("unsuccessful"),
            icon: "XIcon",
            variant: "danger",
            text: this.$t("notSolved"),
          },
        });
      }
    },
    showSaveToast(saved) {
      if (saved)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$t("saved", { type: this.$t("formula") }),
          },
        });
      else
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("unsuccessful"),
            icon: "XIcon",
            variant: "danger",
            text: this.$t("notSolved", { type: this.$t("formula") }),
          },
        });
    },
    async saveFormula() {
      await setTimeout(async () => {
        const saved = await this.$store.dispatch(
          "formulasModule/saveMultiGroup",
          {
            plantid: this.$store.state.app.selectedPlantId,
            multiid: this.formulaData.id,
          }
        );
        this.showSaveToast(saved);
      }, 300);
    },
    saveFormulaForProduction() {
      this.$store.dispatch("formulasModule/saveFormulaForProduction");
    },
    setSelectedSingleFormula(formula) {
      this.$store.commit("formulasModule/SET_SELECTED_SINGLE_FORMULA", formula);
    },
    setOptions({ useMinProd, showInventoryCost }) {
      this.$store.commit("formulasModule/SET_FORMULA_OPTIONS", {
        useMinProd,
        showInventoryCost,
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.m-small {
  margin-top: 0.2rem;
  @media screen and (max-width: 768px) {
    margin-left: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
  }
}
.bg-yellow {
  background-color: #fff820 !important;
  color: #5e5874 !important;
}

@media (max-width: 991px) {
  .modal-xl {
    max-width: auto !important;
  }
}
@media (max-width: 1210px) {
  .modal-dialog {
    max-width: 90%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
  }
}
</style>
