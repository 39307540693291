<template>
  <div>
    <component :is="'b-card'" v-if="multiFormula == null">
      <h4 class="alert-heading">
        {{ $t("xFetchError", { value: $t("formula") }) }}
      </h4>

      <div class="alert-body">
        {{ $t("xNotFound", { value: $t("formula") }) }}
        <b-link class="alert-link" :to="{ name: 'formulas' }"> </b-link>
      </div>
    </component>
    <component :is="'b-card'" v-if="false">
      <h4 class="alert-heading">{{ $t("loading") }}</h4>
    </component>
    <formula-info
      v-if="multiFormula[0]"
      :formula-data="multiFormula[0]"
      class="mt-2 pt-75"
    >
      <b-tabs active pills>
        <!-- Tab: Hammaddeler -->
        <b-tab>
          <template #title active>
            <IngredientSvg height="16" />

            <span class="d-none d-sm-inline">{{ $t("ingredients") }}</span>
          </template>
          <!-- sea -->
          <ingredients />
        </b-tab>
        <!-- Tab: Besin Maddeleri -->
        <b-tab v-if="selectedSingleFormula.id != 0">
          <template #title active>
            <NutrientSvg height="16" />
            <span class="d-none d-sm-inline">{{ $t("nutrients") }}</span>
          </template>
          <nutrients />
        </b-tab>
      </b-tabs>
    </formula-info>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { BCard, BTabs, BTab, BLink } from "bootstrap-vue";
import FormulaInfo from "./Components/FormulaInfo.vue";
import IngredientSvg from "@/assets/images/icons/ingredients.svg";
import NutrientSvg from "@/assets/images/icons/products.svg";

import Ingredients from "./Components/Ingredients.vue";
import Nutrients from "./Components/Nutrients.vue";

export default {
  components: {
    BCard,
    FormulaInfo,
    BTabs,
    BTab,
    IngredientSvg,
    NutrientSvg,
    BLink,
    Ingredients,
    Nutrients,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      multiFormula: (state) => state.formulasModule.multiFormula,
      multiFormulaLoading: (state) => state.formulasModule.multiFormulaLoading,
      selectedSingleFormula: (state) =>
        state.formulasModule.selectedSingleFormula,
    }),
  },
  async mounted() {
    await this.getMultiGroup({
      plantid: this.$route.params.id,
      multiid: this.$route.params.formulaid,
    });
  },
  methods: {
    ...mapActions({
      getMultiGroup: "formulasModule/getMultiGroup",
    }),
  },
};
</script>
<style></style>
