<template>
  <div>
    <!-- Action -->
    <b-row>
      <b-col cols="12" sm="4">
        <h4>{{ $t("totalAmount") }} (Ton): {{ totalAmount }}</h4>
      </b-col>
      <b-col cols="12" sm="4" class="d-flex justify-content-end ml-auto">
        <b-button
          class="d-flex align-items-center ml-1"
          variant="outline-primary"
          size="sm"
          @click="openFormulasModal = !openFormulasModal"
        >
          <feather-icon icon="FilePlusIcon" size="20" />
          <span class="m-small ml-1">{{ $t("addFormulaOrRemove") }}</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-row class="mt-1">
      <b-col cols="12">
        <b-table
          striped
          class="smaller-text"
          :fields="formulaFields"
          responsive=""
          small
          :items="multiFormula[0].formuller"
          :empty-text="$t('noData')"
        >
          <template #cell(uretim)="{ item }">
            <b-form-input v-model="item.uretim"></b-form-input>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-modal
      id="addFormula"
      v-model="openFormulasModal"
      :title="$t('addOrRemoveFormula')"
      size="sm"
      centered
      hide-footer
      class="modal-lg"
    >
      <formula-add-modal @close="openFormulasModal = false"></formula-add-modal>
    </b-modal>
  </div>
</template>

<script>
import { BRow, BCol, BButton, BTable, BFormInput } from "bootstrap-vue";
import FormulaAddModal from "./FormulaAddModal.vue";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BTable,
    FormulaAddModal,
    BFormInput,
  },
  data() {
    return {
      getUserData: getUserData,
      openFormulasModal: false,
      formulaFields: [
        {
          key: "plantName",
          label: this.$t("plantName"),
          formatter: (_, _1, item) => {
            return this.selectedPlantName(item.plantid);
          },
        },
        {
          key: "code",
          label: this.$t("code"),
        },
        {
          key: "rationname",
          label: this.$t("formulaName"),
        },
        {
          key: "uretim",
          label: this.$t("prodAmount"),
          formatter: (val) => val ?? "-",
        },
      ],
    };
  },
  computed: {
    multiFormula() {
      return this.$store.state.formulasModule.multiFormula;
    },
    totalAmount() {
      return (
        +this.multiFormula[0].formuller?.reduce((acc, cur) => {
          return parseFloat(parseFloat(acc) + parseFloat(cur.uretim));
        }, 0) ?? 0
      );
    },
  },
  mounted() {},
  methods: {
    selectedPlantName: function (plantId) {
      const selectedPlantId = this.$route.params.id;
      if (this.$store.state.plantsModule.plantData.length > 0) {
        return (
          this.$store.state.plantsModule.plantData.filter(
            (x) => x.id == plantId
          )[0]?.plantname ??
          this.$store.state.plantsModule.plantData.filter(
            (x) => x.id == selectedPlantId
          )[0]?.plantname ??
          this.$store.state.plantsModule.plantData[0]?.plantname
        );
      } else return "";
    },
  },
};
Array.prototype.unique = () => {
  return this.filter(
    (item, index) => this.findIndex((x) => x === item) === index
  );
};
</script>

<style>
.smaller-text {
  font-size: 0.9rem;
}
#type-group,
#ext-group,
.detail {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding-top: 10px;
}
#type-group,
#ext-group {
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
