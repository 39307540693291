<template>
  <b-card :title="$t('allFormulas')">
    <b-row>
      <b-col cols="12" sm="6" md="3" class="title_group">
        <b-row class="group-item">
          <b-col cols="12">
            <div>
              <price-label
                :label="$t('totalCost') + ' (' + currency + ')'"
                :label-top="multiFormula.uretimfiyati"
              />
            </div>
          </b-col>
          <b-col cols="12">
            <div>
              <price-label
                :label="$t('lastCost') + ' (' + currency + ')'"
                :label-top="multiFormula.oncekifiyat"
              />
            </div>
          </b-col>
          <b-col cols="12">
            <div>
              <price-label
                :label="$t('difference')"
                :label-top="multiFormula.toplammaliyet"
              />
            </div> </b-col
        ></b-row>
      </b-col>
      <b-col v-if="false" cols="12" sm="6" md="3" class="title_group">
        <b-row class="group-item">
          <b-col cols="12">
            <div>
              <price-label :label="$t('?')" :label-top="multiFormula.current" />
            </div>
          </b-col>
          <b-col cols="12">
            <div>
              <price-label
                :label="$t('?')"
                :label-top="multiFormula.oncekifiyat"
              />
            </div>
          </b-col>
          <b-col cols="12">
            <div>
              <price-label
                :label="$t('difference')"
                :label-top="multiFormula.toplammaliyet"
              /></div></b-col
        ></b-row>
      </b-col>
      <b-col cols="12" sm="6" md="3" class="title_group">
        <b-row class="group-item">
          <b-col cols="12">
            <div>
              <price-label
                :label="$t('prodPrice') + ' (' + currency + ')'"
                :label-top="multiFormula.uretimfiyati"
              />
            </div>
          </b-col>
          <b-col cols="12">
            <div>
              <price-label
                :label="$t('prodDifference')"
                :label-top="multiFormula.oncekifiyat"
              />
            </div>
          </b-col>
          <b-col cols="12">
            <div>
              <price-label
                :label="$t('total')"
                :label-top="multiFormula.toplammaliyet"
              /></div></b-col></b-row
      ></b-col>
      <b-col cols="12" sm="6" md="3" class="title_group">
        <b-row class="group-item">
          <b-col cols="12">
            <div>
              <price-label
                :label="$t('currentProdCost') + ' (' + currency + '/TON)'"
                :label-top="multiFormula.uretimfiyati"
              />
            </div>
          </b-col>
          <b-col cols="12">
            <div>
              <price-label
                :label="$t('currentProdDifference') + ' (' + currency + '/TON)'"
                :label-top="multiFormula.oncekifiyat"
              />
            </div>
          </b-col>
          <b-col cols="12">
            <div>
              <price-label
                :label="$t('need') + '(TON)'"
                :label-top="multiFormula.toplammaliyet"
              />
            </div>
          </b-col> </b-row
      ></b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import PriceLabel from "./PriceLabel.vue";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    PriceLabel,
  },
  data: function () {
    return {
      getUserData: getUserData,
      openPrintModal: false,
      openFormulasModal: false,
    };
  },
  computed: {
    multiFormula() {
      return this.$store.state.formulasModule.multiFormula[0];
    },
    currency() {
      return getUserData().currency;
    },
  },

  mounted() {
    if (getUserData().autosolve) {
      this.solveFormula();
    }
  },
};
</script>
<style lang="scss">
.title_group {
  padding: 12px;
}
.dark-layout .group-item {
  border-color: #404656;
  border-top-color: rgb(64, 70, 86);
  border-right-color: rgb(64, 70, 86);
  border-bottom-color: rgb(64, 70, 86);
  border-left-color: rgb(64, 70, 86);
}
.group-item {
  height: 100%;
  margin: 0px 0px;
  padding: 10px;
  border: 1px solid;
  border-top-color: rgb(216, 214, 222);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgb(216, 214, 222);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgb(216, 214, 222);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgb(216, 214, 222);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 8px;
}
</style>
