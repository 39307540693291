<template>
  <div>
    <div class="d-flex justify-content-between">
      <div></div>
      <b-button
        v-b-modal.addNutrientModal
        class="mb-2 align-self-end"
        variant="outline-primary"
        @click="getFormulaNutrients"
      >
        {{ $t("nutrientAdd") }}
      </b-button>
    </div>
    <div v-if="multiFormulaLoading" class="d-flex align-items-center">
      <strong>{{ $t("loading") }}</strong>
      <b-spinner class="ml-auto" />
    </div>
    <b-table
      v-else
      striped
      class="smaller-text"
      responsive=""
      small
      bordered
      :items="
        multiFormula[0].besinMaddeleri.filter(
          (x) => x.rasyonid == selectedSingleFormula.id
        )
      "
      :fields="besinMaddeleriFields"
      :empty-text="$t('noData')"
    >
      <template #cell(deger)="data">
        <span
          :id="data.item.nkey + '-deger'"
          :class="
            data.item.itemtextcolor == 'Green' ? 'text-success' : 'text-danger'
          "
        >
          {{
            parseFloat(data.item.deger)
              .toFixed(nutrientDecimal)
              .replace(".", ",")
          }}</span
        >
      </template>
      <template #cell(aktif)="data">
        <div class="d-flex pointer-event">
          <b-form-checkbox v-model="data.item.aktif" size="md" />
        </div>
      </template>
      <template #cell(min)="data">
        <div class="editable text-right align-items-center position-relative">
          <feather-icon
            v-if="data.item.min > data.item.max && data.item.max != 0"
            class="position-absolute"
            :class="
              data.item.min > data.item.max && data.item.max != 0
                ? 'text-danger'
                : 'text-success'
            "
            icon="ArrowDownIcon"
            style="right: 0; top: 1px"
          />
          <b-form-input
            :id="data.item.ingredientname + 'min'"
            class="inp"
            :class="
              data.item.min > data.item.max && data.item.max != 0
                ? 'text-danger'
                : 'text-success'
            "
            size="sm"
            style="font-size: 1rem"
            :data-id="'nutrient-' + data.field.key + data.index"
            :value="
              parseFloat(data.item.min)
                .toFixed(nutrientDecimal)
                .replace(',', '.')
            "
            type="number"
            :options="{
              numeral: true,
            }"
            @change="(v) => (data.item.min = parseFloat(v))"
            @focus="(v) => v.target.select()"
          />
        </div>
      </template>
      <template #cell(max)="data">
        <div class="editable align-items-center text-right position-relative">
          <feather-icon
            v-if="data.item.min > data.item.max && data.item.max != 0"
            class="position-absolute"
            style="right: 0; top: 1px"
            :class="
              data.item.min > data.item.max && data.item.max != 0
                ? 'text-danger'
                : 'text-success'
            "
            icon="ArrowUpIcon"
          />
          <b-form-input
            :id="data.item.nutrientname + 'max'"
            class="inp"
            size="sm"
            style="font-size: 1rem"
            :data-id="'nutrient-' + data.field.key + data.index"
            :value="
              parseFloat(data.item.max)
                .toFixed(nutrientDecimal)
                .replace(',', '.')
            "
            type="number"
            :options="{
              numeral: true,
            }"
            @change="(v) => (data.item.max = parseFloat(v))"
            @focus="(v) => v.target.select()"
          />
        </div>
      </template>
    </b-table>
    <b-modal
      id="addNutrientModal"
      scrollable
      :title="$t('nutrients')"
      cancel-title="Close"
      :ok-title="$t('ok')"
      ok-only
      @ok="submitNutrient"
    >
      <b-form-group :label="$t('search')">
        <b-form-input v-model="search" @keyup="changeItem()" />
      </b-form-group>
      <b-table-lite
        :items="allNutrientsSearch"
        :fields="[
          {
            key: 'selected',
            label: '',
          },
          {
            key: 'nutrientname',
            label: $t('nutrientName'),
          },
        ]"
      >
        <template #cell(selected)="{ item }">
          <div class="d-flex">
            <b-form-checkbox
              :key="item.id + '-' + item.selected"
              :checked="item.selected == 1"
              @change.native="changeSelected(item)"
            ></b-form-checkbox>
          </div>
        </template>
      </b-table-lite>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  BTable,
  BButton,
  BTableLite,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    BTable,
    BButton,
    BTableLite,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
  },
  data() {
    return {
      search: "",
      allNutrientsSearch: [],
      besinMaddeleriFields: [
        {
          key: "aktif",
          label: this.$t("aktif"),
          // editable: true,
          type: "checkbox",
          class: "is-active-col",
        },
        {
          key: "kod",
          label: this.$t("code"),
          class: "kod-col",
        },
        {
          key: "besinmaddesi",
          label: this.$t("nutrientName"),
          class: "name-col",
        },
        { key: "arrow", label: "", class: "arrow-col" },
        {
          key: "deger",
          label: this.$t("value"),
          class: "text-right",
          formatter: (val) => parseFloat(val).toFixed(this.nutrientDecimal),
        },
        {
          key: "min",
          label: this.$t("Min"),
          editable: true,
          type: "number",
          class: "min-col text-right",
        },
        {
          key: "max",
          label: this.$t("Max"),
          editable: true,
          type: "number",
          class: "max-col text-right",
        },

        {
          key: "kayitlideger",
          label: this.$t("productionValue"),
          class: "text-right",
          formatter: (val) => parseFloat(val).toFixed(this.nutrientDecimal),
        },
        {
          key: "kayitlidanfark",
          label: this.$t("productionDifference"),
          class: "text-right",
          formatter: (val) => parseFloat(val).toFixed(this.nutrientDecimal),
        },

        {
          key: "oncekideger",
          label: this.$t("previousValue"),
          class: "text-right",
          formatter: (val) => parseFloat(val).toFixed(this.nutrientDecimal),
        },

        {
          key: "oncekindenfark",
          label: this.$t("lastDifference"),
          class: "text-right",
        },

        {
          key: "onerilendeger",
          class: "text-right",
          label: this.$t("adviceValue"),
          formatter: (val) => parseFloat(val).toFixed(this.nutrientDecimal),
        },
        {
          key: "onerilenfiyat",
          class: "text-right",
          label: this.$t("adviceDiscount"),
          formatter: (val) => parseFloat(val).toFixed(this.priceDecimal),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      multiFormula: (state) => state.formulasModule.multiFormula,
      multiFormulaLoading: (state) => state.formulasModule.multiFormulaLoading,
      selectedSingleFormula: (state) =>
        state.formulasModule.selectedSingleFormula,
      allNutrients: (state) => state.formulasModule.allNutrients,
    }),
    nutrientDecimal() {
      return getUserData().nutrientdecimal;
    },
    priceDecimal() {
      return getUserData().pricedecimal;
    },
    items() {
      if (this.multiFormula) {
        if (this.selectedSingleFormula.id === 0)
          return this.multiFormula[0].tumFormuller;
        else
          return this.multiFormula[0].besinMaddeleri.filter(
            (x) => x.rasyonid == this.selectedSingleFormula.id
          );
      }
      return {};
    },
  },
  methods: {
    async getFormulaNutrients() {
      const ids =
        this.selectedSingleFormula.id === 0
          ? this.multiFormula[0].tumFormuller.map((x) => x.ikey)
          : this.multiFormula[0].besinMaddeleri
              .filter((x) => x.rasyonid == this.selectedSingleFormula.id)
              .map((x) => x.id);
      await this.$store.dispatch("formulasModule/getFormulaNutrients", ids);
      this.allNutrientsSearch = this.allNutrients;
    },
    submitNutrient() {
      this.$store.dispatch("formulasModule/updateMultiNutrients");
    },
    changeSelected(item) {
      item.selected == 1 ? (item.selected = 0) : (item.selected = 1);
    },
    changeItem() {
      this.allNutrientsSearch = this.allNutrients.filter((x) => {
        return x.nutrientname
          .toLowerCase()
          .startsWith(this.search.toLowerCase());
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.closeButton {
  padding: 0px;
  position: absolute;
  right: 16px;
  top: -5px;
  cursor: pointer;

  :hover {
    color: #b50404;
    transition: all 301ms ease;
  }
}
</style>
<style>
table.editable-table {
  margin: auto;
}
.editable-table .data-cell {
  vertical-align: middle;
}
.editable-table .custom-checkbox {
  width: 50px;
}
.name-col {
  width: 200px;
}
.kod-col {
  width: 50px;
}
.min-col {
  max-width: 100px;
}
.min-col ::after {
  content: "";
  margin-left: auto;
  margin-top: auto;
  font-size: 10px;
}
.min-col:hover {
  cursor: pointer;
}
.max-col {
  max-width: 100px;
}
.max-col ::after {
  content: "";
  margin-left: auto;
  margin-top: auto;
  font-size: 10px;
}
.max-col .max-col:hover {
  cursor: pointer;
}
.price-col ::after {
  content: "";
  margin-left: auto;
  margin-top: auto;
  font-size: 10px;
}
.price-col:hover {
  cursor: pointer;
}
.little-col {
  width: 50px;
}
.age-col {
  width: 100px;
}
.date-col {
  width: 200px;
}
.is-active-col {
  width: 50px;
}
.editable > input {
  min-width: 100px;
}
.min-w-t > table {
  width: 100%;
}
.min-w-t > table.b-table-lite {
  width: inherit !important;
  min-width: 1080px;
}
.editable-table td {
  border-left: 1px solid #e6e6e6;
}
</style>
