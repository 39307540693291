<template>
  <div>
    <div class="d-flex justify-content-between">
      <div></div>
      <b-button
        v-b-modal.addIngredientModal
        class="mb-2 align-self-end"
        variant="outline-primary"
        @click="getFormulaIngredients"
      >
        {{ $t("ingredientAdd") }}
      </b-button>
    </div>

    <div v-if="multiFormulaLoading" class="d-flex align-items-center">
      <strong>{{ $t("loading") }}</strong>
      <b-spinner class="ml-auto" />
    </div>
    <!-- Tum Formuller -->
    <b-table
      v-else-if="selectedSingleFormula.id == 0"
      striped
      class="smaller-text"
      responsive=""
      small
      bordered
      :fields="tumFormullerFields"
      :items="multiFormula[0].tumFormuller"
      :empty-text="$t('noData')"
      show-empty
    >
      <!-- Karışım -->
      <template #cell(karisim)="data">
        <div class="text-right">
          <span
            :id="data.item.ikey + '-karisim'"
            :class="
              data.item.itemtextcolor == 'Green'
                ? 'text-success'
                : 'text-danger'
            "
          >
            {{
              parseFloat(data.item.karisim)
                .toFixed(ingredientDecimal)
                .replace(".", ",")
            }}</span
          >
        </div>
      </template>
      <!-- Stok -->
      <template #cell(stok)="data">
        <div class="d-flex pointer-event">
          <b-form-checkbox
            :checked="data.item.stok"
            size="md"
            @change.native="data.item.stok = !data.item.stok"
          ></b-form-checkbox>
        </div>
      </template>
      <!-- Aktif -->
      <template #cell(aktif)="data">
        <div class="d-flex pointer-event">
          <b-form-checkbox
            :checked="data.item.aktif"
            size="md"
            @change.native="data.item.aktif = !data.item.aktif"
          ></b-form-checkbox>
        </div>
      </template>
      <!-- Min Değer -->
      <template #cell(min)="data">
        <div class="editable text-right align-items-center position-relative">
          <feather-icon
            v-if="data.item.min > data.item.max && data.item.max != 0"
            :class="
              data.item.min > data.item.max && data.item.max != 0
                ? 'text-danger'
                : 'text-success'
            "
            class="position-absolute"
            style="right: 0; top: 1px"
            icon="ArrowDownIcon"
          />
          <b-form-input
            :id="data.item.ingredientname + 'min'"
            class="inp p-relative"
            size="sm"
            style="font-size: 1rem"
            :data-id="'nutrient-' + data.field.key + data.index"
            :value="
              parseFloat(data.item.min)
                .toFixed(ingredientDecimal)
                .replace(',', '.')
            "
            type="number"
            :options="{
              numeral: true,
            }"
            :class="
              data.item.min > data.item.max && data.item.max != 0
                ? 'text-danger'
                : 'text-success'
            "
            @change="(v) => (data.item.min = parseFloat(v))"
            @focus="(v) => v.target.select()"
          />
        </div>
      </template>
      <!-- Max Değer -->
      <template #cell(max)="data">
        <div class="editable text-right">
          <b-form-input
            :id="data.item.ingredientname + 'max'"
            class="inp"
            size="sm"
            style="font-size: 1rem"
            :data-id="'ingredient-' + data.field.key + data.index"
            :value="
              parseFloat(data.item.max)
                .toFixed(ingredientDecimal)
                .replace(',', '.')
            "
            type="number"
            :options="{
              numeral: true,
            }"
            @change="(v) => (data.item.max = parseFloat(v))"
            @focus="(v) => v.target.select()"
          />
        </div>
      </template>
      <template #cell(kayitlidanfark)="data">
        <div class="text-right">
          <span
            :class="
              data.item.karisim - data.item.kayitlikarisim >= 0
                ? ''
                : 'text-danger'
            "
          >
            {{
              (
                parseFloat(data.item.karisim) -
                parseFloat(data.item.kayitlikarisim)
              ).toFixed(ingredientDecimal)
            }}
          </span>
        </div>
      </template>
    </b-table>
    <!-- Normal Hammaddeler -->
    <b-table
      v-else
      striped
      class="smaller-text"
      responsive=""
      small
      bordered
      :fields="hammaddelerFields"
      :items="
        multiFormula[0].hammaddeler.filter(
          (x) => x.rasyonid == selectedSingleFormula.id
        )
      "
      :empty-text="$t('noData')"
      show-empty
    >
      <!-- Karışım -->
      <template #cell(karisim)="data">
        <div class="text-right">
          <span
            :id="data.item.ikey + '-karisim'"
            :class="
              data.item.itemtextcolor == 'Green'
                ? 'text-success'
                : 'text-danger'
            "
          >
            {{
              parseFloat(data.item.karisim)
                .toFixed(ingredientDecimal)
                .replace(".", ",")
            }}</span
          >
        </div>
      </template>
      <template #cell(stok)="data">
        <div class="d-flex pointer-event">
          <b-form-checkbox
            :checked="data.item.stok"
            size="md"
            @change.native="data.item.stok = !data.item.stok"
          ></b-form-checkbox>
        </div>
      </template>
      <!-- Aktif -->
      <template #cell(aktif)="data">
        <div class="d-flex pointer-event">
          <b-form-checkbox
            :checked="data.item.aktif"
            size="md"
            @change.native="data.item.aktif = !data.item.aktif"
          ></b-form-checkbox>
        </div>
      </template>
      <!-- Min Değer -->
      <template #cell(min)="data">
        <div class="editable text-right align-items-center position-relative">
          <feather-icon
            v-if="data.item.min > data.item.max && data.item.max != 0"
            :class="
              data.item.min > data.item.max && data.item.max != 0
                ? 'text-danger'
                : 'text-success'
            "
            class="position-absolute"
            style="right: 0; top: 1px"
            icon="ArrowDownIcon"
          />
          <b-form-input
            :id="data.item.ingredientname + 'min'"
            class="inp p-relative"
            size="sm"
            style="font-size: 1rem"
            :data-id="'nutrient-' + data.field.key + data.index"
            :value="
              parseFloat(data.item.min)
                .toFixed(ingredientDecimal)
                .replace(',', '.')
            "
            type="number"
            :options="{
              numeral: true,
            }"
            :class="
              data.item.min > data.item.max && data.item.max != 0
                ? 'text-danger'
                : 'text-success'
            "
            @change="(v) => (data.item.min = parseFloat(v))"
            @focus="(v) => v.target.select()"
          />
        </div>
      </template>
      <!-- Max Değer -->
      <template #cell(max)="data">
        <div class="editable text-right">
          <b-form-input
            :id="data.item.ingredientname + 'max'"
            class="inp"
            size="sm"
            style="font-size: 1rem"
            :data-id="'ingredient-' + data.field.key + data.index"
            :value="
              parseFloat(data.item.max)
                .toFixed(ingredientDecimal)
                .replace(',', '.')
            "
            type="number"
            :options="{
              numeral: true,
            }"
            @change="(v) => (data.item.max = parseFloat(v))"
            @focus="(v) => v.target.select()"
          />
        </div>
      </template>
      <!-- Aşağı yukarı -->
      <template #cell(arrow)="{ item }">
        <feather-icon
          v-if="
            item.onerilenkarisim != 0 && item.onerilenkarisim > item.karisim
          "
          icon="ArrowUpIcon"
          class="text-success"
        />
        <feather-icon
          v-if="
            item.onerilenkarisim != 0 && item.onerilenkarisim < item.karisim
          "
          icon="ArrowDownIcon"
          class="text-danger"
        />
      </template>
      <template #cell(kayitlidanfark)="data">
        <div class="text-right">
          <span
            :class="
              data.item.karisim - data.item.kayitlikarisim >= 0
                ? ''
                : 'text-danger'
            "
          >
            {{
              (
                parseFloat(data.item.karisim) -
                parseFloat(data.item.kayitlikarisim)
              ).toFixed(priceDecimal)
            }}
          </span>
        </div>
      </template>
    </b-table>
    <b-modal
      id="addIngredientModal"
      scrollable
      :title="$t('ingredients')"
      cancel-title="Close"
      :ok-title="$t('ok')"
      ok-only
      @ok="submitIngredient"
    >
      <b-form-group :label="$t('search')">
        <b-form-input v-model="search" class="inp" @keyup="changeItem()" />
      </b-form-group>
      <b-table-lite
        :items="allIngredientsSearch"
        :fields="[
          {
            key: 'selected',
            label: '',
          },
          {
            key: 'ingredientname',
            label: $t('ingredientName'),
          },
        ]"
      >
        <template #cell(selected)="{ item }">
          <div class="d-flex">
            <b-form-checkbox
              :key="item.id + '-' + item.selected"
              :checked="item.selected == 1"
              @change.native="changeSelected(item)"
            ></b-form-checkbox>
          </div>
        </template>
      </b-table-lite>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  BTable,
  BButton,
  BTableLite,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BTable,
    BButton,
    BTableLite,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
  },
  data() {
    return {
      search: "",
      allIngredientsSearch: [],
      hammaddelerFields: [
        {
          key: "aktif",
          label: this.$t("aktif"),
          class: "is-active-col little-col",
          formatter: (val) => "evet",
        },
        {
          key: "stok",
          label: this.$t("stok"),
          class: "is-active-col",
        },
        {
          key: "kod",
          label: this.$t("code"),
          class: "kod-col",
        },
        {
          key: "hammadde",
          label: this.$t("ingredientName"),
          class: "name-col",
        },
        { key: "arrow", label: "", class: "arrow-col" },
        { key: "karisim", label: this.$t("mixValue"), class: "text-right" },
        {
          key: "min",
          label: this.$t("min"),
          editable: true,
          type: "number",
        },
        {
          key: "max",
          label: this.$t("max"),
          editable: true,
          type: "number",
        },
        {
          key: "fiyat",
          label:
            this.$t("price") + " (" + getUserData().currency + "/Ton" + ")",
          editable: true,
          type: "number",
          class: "price-col text-right",
          formatter: (val) => parseFloat(val).toFixed(this.priceDecimal),
        },
        {
          key: "minfiyat",
          class: "text-right",
          label: this.$t("minPrice"),
          formatter: (val) => parseFloat(val).toFixed(this.priceDecimal),
        },
        {
          key: "maxfiyat",
          class: "text-right",
          label: this.$t("maxPrice"),
          formatter: (val) => parseFloat(val).toFixed(this.priceDecimal),
        },
        {
          key: "kayitlikarisim",
          label: this.$t("productionMixValue"),
          class: "text-right",
          formatter: (val) => parseFloat(val).toFixed(this.ingredientDecimal),
        },
        {
          key: "kayitlidanfark",
          class: "text-right",
          label: this.$t("productionDifference"),
        },
        {
          key: "oncekikarisim",
          class: "text-right",
          label: this.$t("lastMix"),
          formatter: (_, _1, item) =>
            parseFloat(item.oncekikarisim).toFixed(this.ingredientDecimal),
        },
        {
          key: "oncekindenfark",
          class: "text-right",
          label: this.$t("lastDifference"),
          formatter: (_, _1, item) =>
            (parseFloat(item.karisim) - parseFloat(item.oncekikarisim)).toFixed(
              1
            ),
        },
        {
          key: "onerilenkarisim",
          label: this.$t("adviceMix"),
          class: "text-right",
          formatter: (val) => parseFloat(val).toFixed(this.ingredientDecimal),
        },
        {
          key: "onerilenfiyat",
          class: "text-right",
          label: this.$t("adviceDiscount"),
          formatter: (val) => parseFloat(val).toFixed(this.priceDecimal),
        },
      ],
      tumFormullerFields: [
        { key: "stok", label: this.$t("stok"), sortable: false },
        { key: "siralikod", label: this.$t("code"), sortable: true },
        { key: "hammadde", label: this.$t("ingredient"), sortable: true },
        { key: "arrow", label: "", class: "arrow-col" },

        {
          key: "karisim",
          label: this.$t("mix"),
          sortable: true,
          formatter: (val) => parseFloat(val).toFixed(this.ingredientDecimal),
        },
        {
          key: "min",
          label: this.$t("Min"),
          sortable: false,
        },
        {
          key: "max",
          label: this.$t("Max"),
          sortable: false,
        },
        {
          key: "fiyat",
          label: this.$t("price"),

          sortable: false,
          formatter: (val) => parseFloat(val).toFixed(this.priceDecimal),
        },
        {
          key: "minfiyat",
          label: this.$t("minPrice"),
          sortable: false,
          formatter: (val) => parseFloat(val).toFixed(this.priceDecimal),
        },
        {
          key: "maxfiyat",
          label: this.$t("maxPrice"),
          sortable: false,
          formatter: (val) => parseFloat(val).toFixed(this.priceDecimal),
        },
        {
          key: "kayitlikarisim",
          label: this.$t("productionMixValue"),
          sortable: false,
          formatter: (val) => parseFloat(val).toFixed(this.ingredientDecimal),
        },
        {
          key: "kayitlidanfark",
          label: this.$t("productionDifference"),
          sortable: false,
        },
        {
          key: "oncekikarisim",
          label: this.$t("lastMix"),
          sortable: false,
          formatter: (val) => parseFloat(val).toFixed(this.ingredientDecimal),
        },
        {
          key: "oncekindenfark",
          label: this.$t("lastDifference"),
          sortable: false,
          formatter: (val) => parseFloat(val).toFixed(this.ingredientDecimal),
        },
        {
          key: "onerilenkarisim",
          label: this.$t("adviceMix"),
          sortable: false,
          formatter: (val) => parseFloat(val).toFixed(this.ingredientDecimal),
        },
        {
          key: "onerilenfiyat",
          label: this.$t("advicePrice"),
          sortable: false,
          formatter: (val) => parseFloat(val).toFixed(this.priceDecimal),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      multiFormula: (state) => state.formulasModule.multiFormula,
      multiFormulaLoading: (state) => state.formulasModule.multiFormulaLoading,
      selectedSingleFormula: (state) =>
        state.formulasModule.selectedSingleFormula,
      allIngredients: (state) => state.formulasModule.allIngredients,
    }),
    items() {
      if (this.multiFormula) {
        if (this.selectedSingleFormula.id === 0)
          return this.multiFormula[0].tumFormuller;
        else
          return this.multiFormula[0].hammaddeler.filter(
            (x) => x.rasyonid == this.selectedSingleFormula.id
          );
      }
      return {};
    },
    ingredientDecimal() {
      return getUserData().ingredientdecimal;
    },
    priceDecimal() {
      return getUserData().pricedecimal;
    },
  },
  methods: {
    async getFormulaIngredients() {
      const ids =
        this.selectedSingleFormula.id === 0
          ? this.multiFormula[0].tumFormuller.map((x) => x.ikey)
          : this.multiFormula[0].hammaddeler
              .filter((x) => x.rasyonid == this.selectedSingleFormula.id)
              .map((x) => x.id);
      await this.$store.dispatch("formulasModule/getFormulaIngredients", ids);
      this.allIngredientsSearch = this.allIngredients;
    },
    submitIngredient() {
      this.$store.dispatch("formulasModule/updateMultiIngredients");
    },
    changeSelected(item) {
      item.selected == 1 ? (item.selected = 0) : (item.selected = 1);
    },
    changeItem() {
      this.allIngredientsSearch = this.allIngredients.filter((x) => {
        return x.ingredientname
          .toLowerCase()
          .startsWith(this.search.toLowerCase());
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.closeButton {
  padding: 0px;
  position: absolute;
  right: 16px;
  top: -5px;
  cursor: pointer;

  :hover {
    color: #b50404;
    transition: all 301ms ease;
  }
}
</style>
<style>
table.editable-table {
  margin: auto;
}
.editable-table .data-cell {
  vertical-align: middle;
}
.editable-table .custom-checkbox {
  width: 50px;
}
.name-col {
  width: 200px;
}
.kod-col {
  width: 50px;
}
.min-col {
  max-width: 150px;
}
.min-col ::after {
  content: "";
  margin-left: auto;
  margin-top: auto;
  font-size: 10px;
}
.min-col:hover {
  cursor: pointer;
}
.max-col {
  max-width: 150px;
}
.max-col ::after {
  content: "";
  margin-left: auto;
  margin-top: auto;
  font-size: 10px;
}
.max-col .max-col:hover {
  cursor: pointer;
}
.price-col ::after {
  content: "";
  margin-left: auto;
  margin-top: auto;
  font-size: 10px;
}
.price-col:hover {
  cursor: pointer;
}
.little-col {
  width: 50px;
}
.age-col {
  width: 100px;
}
.date-col {
  width: 200px;
}
.is-active-col {
  width: 50px;
}
.editable > input {
  min-width: 100px;
}
.min-w-t > table {
  width: 100%;
}
.min-w-t > table.b-table-lite {
  width: inherit !important;
  min-width: 1080px;
}
.editable-table td {
  border-left: 1px solid #e6e6e6;
}
</style>
